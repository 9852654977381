import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';
import { useState, useEffect, useContext, createContext } from "react";


const firebaseConfig = {
  apiKey: "AIzaSyDTKFlJL67D9XXxydMbaZtHxtnIQ64eHw4",
  authDomain: "truebluesites.firebaseapp.com",
  projectId: "truebluesites",
  storageBucket: "truebluesites.appspot.com",
  messagingSenderId: "442952286656",
  appId: "1:442952286656:web:ee9fd428fddb1d1ca64825",
  measurementId: "G-LWW5FTJE7R"
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);

export const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider('6LfqdCYgAAAAAHFQefU54dzF4_5uvSWt9_ss5dqu'),
    isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
  });

export const AuthContext = createContext();

export const AuthContextProvider = props => {
  const [user, setUser] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), setUser, setError)
    return () => unsubscribe()
  }, [])
  return <AuthContext.Provider value={{ user, error }} {...props} />
};

export const useAuthState = () => {
  const auth = useContext(AuthContext);
  return { ...auth, isAuthenticated: auth.user != null }
};
