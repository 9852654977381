import * as React from 'react';
import { Grid, Box } from '@mui/material';
import MainTile from '../atoms/Maintile';
import { Typography, Paper } from '@mui/material';
import useBreakpoint from '../atoms/useBreakpoint';
import CursiveFont from '../atoms/cursiveFont';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Mirror from '../mirror.svg';
import Path from '../path.svg';
import Profile from '../profile.svg';
import Application from '../application.svg';
import Virtual from '../interview.svg';
import Potential from '../potential.svg';
import VideoPlayer from '../molecules/VideoPlayer';
import Professions from '../organisms/Professions';
import Recognition from '../organisms/Recognition';
import PrettyButton from '../atoms/prettyButton';


export default function Career(props) {

    return (
        <React.Fragment>
          <motion.div 
          className=''
          initial={{width: 0}}
          animate={{width: '100%'}}
          exit={{x: window.innerWidth, transition: {duration: 0.3}}}
          >

<Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1, xl:1 }} alignContent="center" alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
          <MainTile>
          <Grid container alignContent="center" alignItems="center">
          <Grid item  xs={12} sm={12} md={12} lg={12} xl={12} p={2} pt={4}  textAlign="center" >
            <PrettyButton component={Link} to="apply" p={4}>Apply Now</PrettyButton>
            </Grid>
            <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}   textAlign="center" >
            <CursiveFont variant="h3"><b><u>Know</u></b> what <b><u>You</u></b> can <b><u>Become</u></b></CursiveFont>
                      <CursiveFont variant="h5">Your Career in Technology is just a click away</CursiveFont>
                   
            </Grid>
            <Grid item  xs={12} sm={12} md={6} lg={12} xl={12} p={2} >
              <Grid container alignContent="center" alignItems="center">
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} p={2} >

                <Box sx={{ flexGrow: 1, display: { xs:'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }, p:2 }} >
                  <img style={{ maxWidth: 650 }} src={Mirror} />
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs:'flex', sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }, p:2 }} alignItems="center" justifyContent="center">
                  <img style={{ maxWidth: 280 }} src={Mirror} />
                </Box>



                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} p={4}>

                <Box sx={{ flexGrow: 1, display: { xs:'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }, }} >
                  <Paper sx={{p:2, pb:6}}>
                <VideoPlayer url="girls.mp4" playing={true} muted={false} loop={true} controls={true} />
                </Paper>
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs:'flex', sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }, }} alignItems="center" justifyContent="center">
   
                <VideoPlayer url="girls.mp4" playing={true} muted={false} loop={true} controls={true} width="240px" />
       
                </Box>
                  
                </Grid>  
                
              </Grid>
                
            </Grid>
            
            </Grid>
          
          </MainTile>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
          <MainTile>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign="center" p={2}>
              <CursiveFont variant="h3"><b><u>Follow</u></b> the <b><u>Path</u></b> that <b><u>Pays</u></b></CursiveFont>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item>
                  <Box sx={{ flexGrow: 1, display: { xs:'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }, p:2 }} >
                        <img style={{ maxWidth: 500 }} src={Path} />
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs:'flex', sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }, p:2 }}>
                        <img style={{ maxWidth: 280 }} src={Path} />
                        </Box>

                  </Grid>
                  <Grid item>
                  <Professions />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item  xs={12} sm={12} md={12} lg={12} xl={12} p={2}  textAlign="center" >
              <PrettyButton component={Link} to="apply">Apply Now</PrettyButton>
            </Grid>
            </Grid>
          </MainTile>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
          <MainTile>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign="center" p={2}>
              <CursiveFont variant="h3">Get <b><u>Recognized</u></b> for your<b> <u>Work</u></b></CursiveFont>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item>

                  <Box sx={{ flexGrow: 1, display: { xs:'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }, p:2 }} >
                        <img style={{ maxWidth: 500 }} src={Profile} />
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs:'flex', sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }, p:2 }}>
                        <img style={{ maxWidth: 280 }} src={Profile} />
                        </Box>

               

                  </Grid>
                  <Grid item>
                  <Recognition />
                  </Grid>
                </Grid>
              </Grid>
             
            </Grid>
          </MainTile>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
          <MainTile>
            <Grid container alignContent="center" alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign="center" p={2}>
              <CursiveFont variant="h3">It's as <b><u>Easy</u></b> as <b><u>1, 2, 3...</u></b></CursiveFont>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} pt={4}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={4} xl={4} textAlign="center" >
                  <Grid container alignContent="center" alignItems="center">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign="center" >
                      <CursiveFont variant="h5" ><b>1)</b> Fill Out an Online Application</CursiveFont>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} p={2} >
                  <Box sx={{ flexGrow: 1, display: { xs:'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }, p:2 }}  alignItems="center" justifyContent="center">
                        <img style={{ maxWidth: 200 }} src={Application} />
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs:'flex', sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }, p:2 }} alignItems="center" justifyContent="center">
                        <img style={{ maxWidth: 180 }} src={Application} />
                        </Box>
                  </Grid>
                </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4} xl={4} textAlign="center" >
                  <Grid container alignContent="center" alignItems="center">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign="center" >
                  <CursiveFont variant="h5"><b>2)</b> Attend a Virtual Interview</CursiveFont>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} p={2} >
                  <Box sx={{ flexGrow: 1, display: { xs:'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }, p:2 }}  alignItems="center" justifyContent="center">
                        <img style={{ maxWidth: 300 }} src={Virtual} />
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs:'flex', sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }, p:2 }} alignItems="center" justifyContent="center">
                        <img style={{ maxWidth: 280 }} src={Virtual} />
                        </Box>
                  </Grid>
                </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4} xl={4} textAlign="center" >
                  <Grid container alignContent="center" alignItems="center">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign="center" >
                  <CursiveFont variant="h5"><b>3)</b> Start Tranforming Your Potential</CursiveFont>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} p={2}>
                  <Box sx={{ flexGrow: 1, display: { xs:'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }, p:2 }} alignItems="center" justifyContent="center">
                        <img style={{ maxWidth: 280 }} src={Potential} />
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs:'flex', sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }, p:2 }} alignItems="center" justifyContent="center">
                        <img style={{ maxWidth: 280 }} src={Potential} />
                        </Box>
                  </Grid>
                </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item  xs={12} sm={12} md={12} lg={12} xl={12} p={2}  textAlign="center" >
              <PrettyButton component={Link} to="apply">Apply Now</PrettyButton>
            </Grid>
            </Grid>
          </MainTile>
        </Grid>
      </Grid>



</motion.div>
    </React.Fragment>
  );

}