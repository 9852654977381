import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MainTile from '../atoms/Maintile';
import { Grid } from '@mui/material';
import ContactForm from '../organisms/ContactForm';
import HomeMap from '../molecules/HomeMap';
import CursiveFont from '../atoms/cursiveFont';
import HSpecial from '../atoms/hSpecial';
import FormDialog from '../organisms/FormDIalog';
import Mobile from '../mobilem.svg';
import MapLetter from '../mapletter.svg';
import Sonic from '../sonic.svg';
import Snailmail from '../snailm.svg';
import { motion } from 'framer-motion';


export default function contactUs() {
  return (
    <React.Fragment>
      <motion.div 
      className=''
      initial={{width: 0}}
      animate={{width: '100%'}}
      exit={{x: window.innerWidth, transition: {duration: 0.3}}}
      >
   <MainTile>
      <Grid container alignContent="center" alignItems="center">
     
        <Grid item xs={12} sm={12} md={12} lg={8} xl={8} style={{textAlign: "center", marginBottom: 25, marginTop: 25}}>
          <Grid container spacing={6} alignContent="center" alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid container alignContent="center" alignItems="center">
                <Grid item xs={12} sm={12} md={12} lg={8} xl={8} order={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }} >
                  <HSpecial variant="h4" >How quick would you like to connect?</HSpecial>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4} order={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }} >
                  <img style={{ maxWidth: 220 }} src={MapLetter} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container alignContent="center" alignItems="center">
              
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}><CursiveFont variant="h6" >Super Sonic</CursiveFont><img style={{ maxWidth: 180 }} src={Sonic} /></Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}><FormDialog /></Grid>
              
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid container alignContent="center" alignItems="center" alignSelf="center">
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}><CursiveFont variant="h6" >Snail Mail</CursiveFont><img style={{ maxWidth: 180 }} src={Snailmail} /></Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}><CursiveFont variant="h6" >2205 W. Balboa Blvd. Unit B<br />Newport Beach, CA 92663</CursiveFont></Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mb={4}>
              <Grid container alignContent="center" alignItems="center">
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}><CursiveFont variant="h6" >...Phone?</CursiveFont><img style={{ maxWidth: 180 }} src={Mobile} /></Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}><CursiveFont variant="p" >Currently Not Available - Coming Winter 2022</CursiveFont></Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
            
        <Grid item  xs={12} sm={12} md={12} lg={4} xl={4} style={{textAlign: "center", }}><HomeMap /></Grid>
        
      </Grid>
   </MainTile>
   </motion.div>
    </React.Fragment>
  );
}