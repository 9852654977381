import React from 'react'
import { GoogleMap, useJsApiLoader, Marker, InfoBox } from '@react-google-maps/api';
import { Container, Box, border } from '@mui/system';


const containerStyle = {
  width: '350px',
  height: '350px',
  border: '10px solid white',

};


const center = {
  lat: 33.6097,
  lng: -117.929
};

function HomeMap() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBh7E4bzGTfcAsHhGM2doiIWvMuSWmk93g"
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return isLoaded ? (
      <Container  ><Box
      sx={{
        width: 400,
        height: 400,
        backgroundColor: 'primary.main',
        '&:hover': {
          backgroundColor: 'secondary.main',
          opacity: [0.9, 0.8, 0.9],
        },
      }}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={5}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {<Marker position={center} label="Answers, LLC"></Marker> /* Child components, such as markers, info windows, etc. */ }
        <></>
      </GoogleMap>
      </Box></Container>
  ) : <></>
}

export default React.memo(HomeMap)