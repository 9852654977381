import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Link } from 'react-router-dom';
import cursiveFont from '../atoms/cursiveFont';
import NavButton from '../atoms/navButton';
import { useState } from 'react';
import { Sling as Hamburger } from 'hamburger-react'
import MobileNav from '../molecules/MobileNav';
import { Grid, Dialog } from "@material-ui/core";





export default function AppMenu(props) {

  const [isOpen, setOpen] = useState(false);

  
  
  return (
    <React.Fragment>
      <Grid container justifyContent="flex-end" >
        <Grid item >
          <Box sx={{ flexGrow: 1, display: { xs:'flex', sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }, mr:6   }}> 
            <Hamburger 
              toggled={isOpen} 
              toggle={setOpen} 
              label="Show menu"
              rounded
              easing="ease-in"
              size={48}
              
            />
          </Box>
        </Grid>
        <Dialog
         PaperProps={{
           style: {
             backgroundColor: 'transparent',
             boxShadow: 'none',
           },
         }}
          fullScreen
          open={isOpen}
        
        >
  <Container direction="row"
  justifyContent="flex-end" >
          <Box sx={{ flexGrow: 1, display: { xs:'flex', sm: 'flex', md: 'flex', lg: 'none', xl: 'none' } , background:'white', justifyContent: 'flex-end', ml:-2, mr:-2 }}> 
            <Grid container  direction="row"
  justifyContent="flex-end" >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Box sx={{  flexGrow: 1, display: { xs:'flex', sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }, justifyContent: 'flex-end', mr:4, mt:2 }}>
                 <Hamburger 
              toggled={isOpen} 
              toggle={setOpen} 
              rounded
              easing="ease-in"
              size={48}
            /></Box>
            </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}><MobileNav /></Grid>
            </Grid>

   
            </Box>
            </Container>
            </Dialog>
            <Box sx={{ flexGrow: 1, display: { xs:'none', sm: 'none', md: 'none', lg:'flex', xl:'flex' } }} > 

            <MobileNav />
       

            </Box>
   
       
        </Grid>
    </React.Fragment>
  );
}