import React, { Component, useState } from 'react'
import NavButton from '../atoms/navButton'
import { Grid, Typography, Box, Divider,  } from '@mui/material'
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import LevelUp from '../molecules/LevelUp';
import Apprentice from '../molecules/Apprentice';
import Journeyman from '../molecules/Journeyman';
import Master from '../molecules/Master';


export default function Professions(){

  
  

    const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
    return (
        <>
       <Box sx={{ maxWidth: { xs: 320, sm: 480, md:600, lg:900, xl:900 }, typography: 'body1' }}>
      <TabContext value={value}
      
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example"
           variant="scrollable"
           scrollButtons
            allowScrollButtonsMobile
            textColor="secondary"
            sx={{
              "& .MuiTabs-indicator": { backgroundColor: "#2f2e41" },
              "& .MuiTab-root.Mui-selected": { color: "#2f2e41", fontWeight:"700", fontSize:"24px" },
            }}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#2f2e41"
               }
              }}
              centered
          >
            <Tab  label="Level Up" value="1" />
            <Tab label="Apprentice" value="2" />
            <Tab label="Journeyperson" value="3" />
            <Tab label="Master" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1"><LevelUp /></TabPanel>
        <TabPanel value="2"><Apprentice /></TabPanel>
        <TabPanel value="3"><Journeyman /></TabPanel>
        <TabPanel value="4"><Master /></TabPanel>
      </TabContext>
    </Box>





          </>
    );

}
