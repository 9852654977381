import React, { Component } from 'react'
import NavButton from '../atoms/navButton'
import { Grid, Typography, Box, Divider  } from '@mui/material'


export default class MobileNav extends Component {
  render() {
    return (
        <>
          <Box sx={{ flexGrow: 1, display: { xs:'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' } }}>
            <Grid container>
              <Grid item  xs={12} sm={12} md={12} lg={4}><NavButton address="/mission" ><Typography variant="h5">Mission</Typography></NavButton></Grid>
              <Grid item xs={12} sm={12} md={12} lg={4}><NavButton address="/careers" ><Typography variant="h5">Careers</Typography></NavButton></Grid>
              <Grid item xs={12} sm={12} md={12} lg={4}><NavButton address="/contactus" ><Typography variant="h5">Contact Us</Typography></NavButton></Grid>
            </Grid>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs:'flex', sm: 'flex', md: 'flex', lg: 'none', xl: 'none' } }}>
          <Grid container >
          
            <Grid item  xs={12} sm={12} md={12} lg={4}><Divider light /><NavButton address="/mission" ><Typography variant="h3">Mission</Typography></NavButton><Divider light /></Grid>
            
            <Grid item xs={12} sm={12} md={12} lg={4}><NavButton address="/careers" ><Typography variant="h3">Careers</Typography></NavButton><Divider light /></Grid>
          
            <Grid item xs={12} sm={12} md={12} lg={4} ><NavButton address="/contactus" ><Typography variant="h3">Contact Us</Typography></NavButton><Divider light /></Grid>

            </Grid >
          </Box>
          </>
    )
  }
}
