import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MainTile from '../atoms/Maintile';
import { Grid } from '@mui/material';
import { motion } from 'framer-motion';
import SinglePaper from '../templates/SinglePaper';
import CursiveFont from '../atoms/cursiveFont';
import Fouro from '../fouro.svg';



export default function noMatch() {
  return (
    <React.Fragment>
      <motion.div 
      className=''
      initial={{opacity:0}}
      animate={{opacity:1}}
      exit={{opacity:0}}
      >
        <SinglePaper Header="Page Not Found">
        <Box sx={{ flexGrow: 1, display: { xs:'none', sm: 'none', md: 'none', lg: 'none', xl: 'flex' } }} alignItems="center" justifyContent="center">
                      <img style={{ maxWidth: 580 }} src={Fouro} />
                      </Box>
                      <Box sx={{ flexGrow: 1, display: { xs:'none', sm: 'none', md: 'none', lg: 'flex', xl: 'none' } }}>
                      <img style={{ maxWidth: 540 }} src={Fouro} />
                      </Box>
                      <Box sx={{ flexGrow: 1, display: { xs:'flex', sm: 'flex', md: 'flex', lg: 'none', xl: 'none' } }}>
                      <img style={{ maxWidth: 380 }} src={Fouro} />
                      </Box>
            </SinglePaper>
      </motion.div>
    </React.Fragment>
  );
}