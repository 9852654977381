import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
import styled from "styled-components";
import {Container} from '@mui/material';
import { animate, motion } from 'framer-motion';  

const StyledButton = styled(Button) `
background-color:black;
color: white;
border-style: solid;
border-width: 1px;
border-color: white;
:hover {
  outline: none;
  border-color: black;
  background-color:white;
  color: black;
}
`

export default function prettyButton(props) {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container whileHover={{ scale: 1.1 }} maxWidth={false} component={motion.div}>
        <StyledButton variant={props.variant} onClick={props.onClick} to={props.to} component={props.component}>
            {props.children}
            </StyledButton>
      </Container>
    </React.Fragment>
  );
}