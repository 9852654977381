import * as React from 'react';
import { Container, Box, Grid, Typography } from '@mui/material';
import CursiveFont from '../atoms/cursiveFont';


export default function footer(props) {
  return (
    <React.Fragment>
  
      <Container justifyContent="center" >
        <Box sx={{flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        }}> 
          <Grid container alignContent="center" rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1, xl:4 }}  justifyContent="center">
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} order={{ xs: 3, sm: 3, md: 3, lg: 1, xl:1 }} 
                sx={{flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  }}
                 >
 
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} order={{ xs: 1, sm: 1, md: 1, lg: 2, xl:2 }}  sx={{flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  }} >
            <CursiveFont align="center">ANSWERS.LLC</CursiveFont>
            <CursiveFont align="center">...built with &hearts;</CursiveFont>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} order={{ xs: 2, sm: 2, md: 2, lg: 3, xl:3 }}>

            </Grid>  
          </Grid>
      

     </Box>
   </Container>
    </React.Fragment>
  );
}