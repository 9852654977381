import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PrettyButton from '../atoms/prettyButton';
import Typography from '@mui/material/Typography';
import ContactForm from './ContactForm';
import CloseIcon from '@mui/icons-material/Close';
import LoaderAnimation from "../atoms/LoaderAnimation";
import Letter from '../letter.svg';

export default function FormDialog() {
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [successful, setSuccessful] = React.useState(false);  
  const [message, setMessage] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
    <PrettyButton variant="outlined" onClick={handleClickOpen}>Connect Here</PrettyButton>
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle align='center'>

        <Typography variant="h4" component="Block" color="black">
          
          
          { !submitted &&
          "How can we help?"
        }

        { loader &&
          "Loading"
        }  
        { successful &&
         "Success"
        }    
        
        </Typography>
        
        
        </DialogTitle>
      <DialogContent>
        <DialogContentText align='center'>


        { !submitted &&
           
           <Typography variant="h6" component="Block" color="black">
           Don't spend another minute
           <br />
           waiting to begin
           </Typography>
          } 
        
          { successful &&
          <Typography variant="h6" component="Block" color="black">
           You'll hear from us shortly!
           </Typography>
          } 
          
         
          
        </DialogContentText>
        { !submitted &&
          <ContactForm setLoader={setLoader} setMessage={setMessage} setSuccessful={setSuccessful} setSubmitted={setSubmitted} />
        }

        { loader &&
          <LoaderAnimation />
        }  
        { successful &&
           <img src={Letter} width={220}/> 
        }    
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}><CloseIcon sx={{ color: 'black' }} /></Button>
      </DialogActions>
    </Dialog>
    </>
  );
}
