import React from 'react';
import {motion} from 'framer-motion';
import '../Answers.css';

const loaderVariants = {
    animationOne: {
        x: [0, 0],
        y: [70,-70],
        transition: {
            x: {
                yoyo: Infinity,
                duration: 0.25
            },
            y: {
                yoyo: Infinity,
                duration: 0.35,
                ease: 'easeOut'
            }
        }
    }
}

const LoaderAnimation = () => {
    return(
        <>
        <motion.div
        className="loader"
        variants={loaderVariants}
        animate="animationOne"
        >

        </motion.div>
        </>
    )

}

export default LoaderAnimation;