import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { motion } from 'framer-motion';



export default function accelerators() {
  return (
    <React.Fragment>
       <motion.div 
      className=''
      initial={{opacity:0}}
      animate={{opacity:1}}
      exit={{opacity:0}}
      >
      <CssBaseline />
      <Container maxWidth={false}>
        <p>Accelerators</p>
      </Container>
      </motion.div>
    </React.Fragment>
  );
}