import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MainTile from '../atoms/Maintile';
import CursiveFont from '../atoms/cursiveFont';
import VideoPlayer from '../molecules/VideoPlayer';
import { Grid } from '@mui/material';
import { motion } from 'framer-motion';
import Light from '../lightmode.svg';
import Mission from '../mission.svg';
import Growth from '../growth.svg';
import Account from '../radical.svg';
import Collaborate from '../wholehearted.svg';
import Creative from '../creative.svg';




export default function mission() {
  return (
    <React.Fragment>
      <motion.div 
      className=''
      initial={{width: 0}}
      animate={{width: '100%'}}
      exit={{x: window.innerWidth, transition: {duration: 0.3}}}
      >

        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1, xl:1 }} alignContent="center" alignItems="center">
          <Grid item>
            <Grid container alignContent="center" rowSpacing={4}>
              <Grid item  xs={12} sm={12} md={6} lg={6} xl={6}>
              <MainTile>
              <Grid container direction="row"
  justifyContent="center"
  alignItems="stretch">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign="center" p={2}>
              <CursiveFont variant="h4">Vision</CursiveFont>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} p={2} textAlign="center">
              <img style={{ maxWidth: 300 }} src={Light} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} p={2} textAlign="center"  justifyContent="center"
  alignItems="stretch" >
              <CursiveFont variant="h6">Our company doesn't have the solution to the world's problems, but we can work with the individuals and/or organizations moving in the right direction. Our goal is to accelerate them. To make them more powerful; which we believe will make a more abundant world. 
              </CursiveFont>
              </Grid>
            </Grid>
            </ MainTile>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} pt={4}>
              <MainTile>
              <Grid container direction="row"
  justifyContent="center"
  alignItems="stretch" >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign="center" p={2} >
              <CursiveFont variant="h4">Mission</CursiveFont>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} p={2} textAlign="center"  pb={4.5} pt={10}>
              <img style={{ maxWidth: 340 }} src={Mission} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} p={2} textAlign="center">
              <CursiveFont variant="h6">We will create a <u>safe environment: to challenge, to test, and to learn</u> about accelerating 
              individuals and organizations dedicated to making a more abundant world. To arm them with the most advanced systems needed for them to meet their challenges head on.</CursiveFont>
              </Grid>
            </Grid>
            </ MainTile>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign="center">
            <MainTile>
          <Grid container pb={4}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign="center" p={2}>
            <CursiveFont variant="h4">Values</CursiveFont>
          </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} p={2}>
              <img style={{ maxWidth: 340 }} src={Growth} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} p={1} pb={1}>
              <CursiveFont variant="h6">Purposeful Growth</CursiveFont>
              </Grid>
            </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} p={2}>
              <img style={{ maxWidth: 340 }} src={Account} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} p={1} pb={1}>
              <CursiveFont variant="h6">Radical Accountability</CursiveFont>
              </Grid>
            </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} p={2}>
              <img style={{ maxWidth: 340 }} src={Collaborate} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} p={1} pb={1}>
              <CursiveFont variant="h6">Wholehearted Collaboration</CursiveFont>
              </Grid>
            </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} p={1}>
              <img style={{ maxWidth: 340 }} src={Creative} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} p={1} pb={1}>
              <CursiveFont variant="h6">Adventurous Creativity</CursiveFont>
              </Grid>
            </Grid>
              </Grid>
            </Grid>
            </MainTile>
          </Grid>
     
        </Grid>
   

      </motion.div>
    </React.Fragment>
  );
}