import * as React from 'react';
import { Grid, Box, List, ListItem, ListItemText, Divider } from '@mui/material';
import Activities from '../activities.svg';
import CursiveFont from '../atoms/cursiveFont';



export default function Journeyman(props) {

    return (
        <React.Fragment>
        <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign="center">
              <Box sx={{ flexGrow: 1, display: { xs:'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }, p:2 }} >
                  <CursiveFont variant="h5">Master Skills While Building Your Brand</CursiveFont>
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs:'flex', sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }, p:2 }}>
                <CursiveFont variant="h5">Master Skills While Building Your Brand</CursiveFont>
                </Box>
             
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item>
                  <Box sx={{ flexGrow: 1, display: { xs:'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }, p:2 }} >
                  <img style={{ maxWidth: 280 }} src={Activities} />
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs:'flex', sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }, p:2 }}>
                  <img style={{ maxWidth: 220 }} src={Activities} />
                </Box>
                  </Grid>
                  <Grid item>
                  <Box sx={{ flexGrow: 1, display: { xs:'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }, p:2 }} >
                  <List>
                    <ListItem disablePadding>
                    <ListItemText>
                    <CursiveFont>Learn From Technology Masters</CursiveFont>
                        </ListItemText> 

                    </ListItem>
                    <ListItem disablePadding>
                    <ListItemText>
                    <CursiveFont>Build a Network of Followers</CursiveFont>
                        </ListItemText> 

                    </ListItem>
                  
                    <ListItem disablePadding>
                    <ListItemText>
                    <CursiveFont>Enjoy Profit Sharing & Flexible Work Hours</CursiveFont>
                        </ListItemText> 

                    </ListItem>
                </List>
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs:'flex', sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }, p:2 }}>
                <List>
                    <ListItem disablePadding>
                    <ListItemText>
                    <CursiveFont>Learn From Technology Masters</CursiveFont>
                        </ListItemText> 

                    </ListItem>
                    <ListItem disablePadding>
                    <ListItemText>
                    <CursiveFont>Build A Network Of Followers</CursiveFont>
                        </ListItemText> 

                    </ListItem>
                  
                    <ListItem disablePadding>
                    <ListItemText>
                    <CursiveFont>Enjoy Profit Sharing & Flexible Work Hours</CursiveFont>
                        </ListItemText> 

                    </ListItem>
                </List>
                </Box>
                    
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            </React.Fragment>
    );


}