import React from 'react'
import Home from '../pages/HomePageBlock';
import NoMatch from '../pages/noMatch';
import Careers from '../pages/Career';
import Accelerators from '../pages/accelerators';
import Mission from '../pages/mission';
import Contactus from '../pages/contactUs';
import Apprentice from '../pages/Apprentice';
import Journeyperson from '../pages/Journeyperson';
import Master from '../pages/Master';
import EmployeeDashboard from '../pages/employeeDashboard';
import Applicationhub from '../pages/Applicationhub';
import FrontLayout from '../templates/frontLayout';
import {
    Routes,
    Route,
    useLocation,
    Navigate 
  } from "react-router-dom";
  import { AnimatePresence } from 'framer-motion';
  import { useAuthState } from '../atoms/firebase';

 

  

function AnimatedRoutes() {
    const location = useLocation();

    const AuthenticatedRoute = ({ component: C, ...props }) => {
      const { isAuthenticated } = useAuthState()
      console.log(`AuthenticatedRoute: ${isAuthenticated}`)
      return (
        <Route
          {...props}
          render={routeProps =>
            isAuthenticated ? <C {...routeProps} /> : <Navigate to="/login" />
          }
        />
      )
    }
    
    const UnauthenticatedRoute = ({ component: C, ...props }) => {
      const { isAuthenticated } = useAuthState()
      console.log(`UnauthenticatedRoute: ${isAuthenticated}`)
      return (
        <Route
          {...props}
          render={routeProps =>
            !isAuthenticated ? <C {...routeProps} /> : <Navigate to="dashboard" />
          }
        />
      )
    }
  return (
      <AnimatePresence >
    <Routes location={location} key={location.pathname}>
    <Route element={<FrontLayout />} >
      <Route exact path="/" element={<Home />}>
        <Route index element={<Home />} />
      </Route>
      <Route exact path="careers" element={<Careers />}>
        
      </Route>
      <Route exact path="careers/apprentice" element={<Apprentice />} />
        <Route exact path="careers/journeyperson" element={<Journeyperson />} />
        <Route exact path="careers/master" element={<Master />} />
        <Route exact path="careers/apply" element={<Applicationhub />} />
      <Route exact path="mission" element={<Mission />}></Route>
      <Route exact path="accelerators" element={<Accelerators />}></Route>
      <Route exact path="contactus" element={<Contactus />}></Route>
      <Route exact path="dashboard" element={<EmployeeDashboard />} />
      <Route exact path="*" element={<NoMatch />}></Route>
     </ Route>

    </Routes>
    </AnimatePresence>
  )
}

export default AnimatedRoutes