import * as React from 'react';
import { Grid } from '@mui/material';
import MainTile from '../atoms/Maintile';
import Footer from '../organisms/footer';
import Logo from '../answersllc.png';
import AppMenu from '../organisms/AppMenu';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { auth, analytics, appCheck } from '../atoms/firebase';
import { useLocation } from "react-router-dom";
import { useEffect } from "react";


import { Outlet } from 'react-router-dom';


export default function frontLayout(props) {

  function FirebaseAnalytics() {
    let location = useLocation();
    useEffect(() => {
        const analytics = window.firebase && window.firebase.analytics;
        if (typeof analytics === "function") {
            const page_path = location.pathname + location.search;
            analytics().setCurrentScreen(page_path);
            analytics().logEvent("page_view", { page_path });
        }
    }, [location]);
    return null;
}

  return (
    <React.Fragment>
      <FirebaseAnalytics />
      <appCheck />
       <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mb={-1} > 
         
               <Grid container ml={4} mt={2}  >
                   <Grid item xs={6} sm={6} md={6} lg={6} xl={6} order={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1}}  ><Link to="/"><Button><img style={{ maxWidth: 380 }} src={Logo} /></Button></ Link></Grid>
                   <Grid item xs={6} sm={6} md={6} lg={6} xl={6} order={{ xs: 2, sm: 2, md: 2, lg: 2, xl:2 }} ><AppMenu /></Grid>
               </Grid>
         
           </Grid>
           <Grid item xs={12} sm={12} md={12} lg={12} xl={12}><p><Outlet /></p></Grid>
           <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ 
                height: 100,
                display: "flex-end",
                justifyContent: "center",
                 }}><Footer/></Grid>
       </Grid>
    </React.Fragment>
  );
}