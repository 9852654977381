import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import styled from "styled-components";

const StyledBox = styled(Box) `
background-color: ${props => props.theme.custom.mainTile.backgroundColor};
margin-top: ${props => props.theme.custom.mainTile.marginTop};
box-shadow: ${props => props.theme.custom.mainTile.boxShadow};
border-style: ${props => props.theme.custom.mainTile.borderStyle};
border-width: ${props => props.theme.custom.mainTile.borderWidth};
border-color: ${props => props.theme.custom.mainTile.borderColor};
`

export default function MainTile(props) {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth={false}>
        <StyledBox sx={{ height: props.height }} >
            {props.children}
            </StyledBox>
      </Container>
    </React.Fragment>
  );
}