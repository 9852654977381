import * as React from 'react';
import { Grid, Box } from '@mui/material';
import MainTile from '../atoms/Maintile';
import Logo from '../answersllc.png';
import CollabTech from '../collabtechfront.png';
import TBC from '../truebluelogo.png';
import DG from '../deskGuy.svg';
import Mobile from '../mobile.svg';
import { Typography } from '@mui/material';
import useBreakpoint from '../atoms/useBreakpoint';
import HSpecial from '../atoms/hSpecial';
import CursiveFont from '../atoms/cursiveFont';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';


export default function HomePageBlock(props) {

  const point = useBreakpoint();

  return (
    <React.Fragment>
      <motion.div 
      className=''
      initial={{width: 0}}
      animate={{width: '100%'}}
      exit={{x: window.innerWidth, transition: {duration: 0.3}}}
      >
        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1, xl:4 }} alignContent="center" alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} ><MainTile >

                <Grid container spacing={4}  alignContent="center" alignItems="center" >
               
                  
                    
                    <Grid item xs={12} sm={12} md={12} lg={4} order={{ xs: 2, sm: 2, md: 2, lg: 1 }} mt={2}>
                    <Box sx={{ paddingTop:10, flexGrow: 1, display: { xs:'none', sm: 'none', md: 'none', lg: 'none', xl: 'flex' } }}>
                      <img style={{ maxWidth: 580 }} src={Mobile} />
                      </Box>
                      <Box sx={{ flexGrow: 1, display: { xs:'none', sm: 'none', md: 'none', lg: 'flex', xl: 'none' } }}>
                      <img style={{ maxWidth: 540 }} src={Mobile} />
                      </Box>
                      <Box sx={{ flexGrow: 1, display: { xs:'flex', sm: 'flex', md: 'flex', lg: 'none', xl: 'none' } }}>
                      <img style={{ maxWidth: 380 }} src={Mobile} />
                      </Box>
                      </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={8} xl={8} order={{ xs: 1, sm: 1, md: 1, lg: 2 }} ml={2} >
                    <Box sx={{ flexGrow: 1, display: { xs:'none', sm: 'none', md: 'none', lg: 'none', xl: 'flex' } }}>
                      <HSpecial variant="h1">Keeping Technology Simple <br /> Because it should be.</HSpecial>
                      </Box>
                      <Box sx={{ flexGrow: 1, display: { xs:'none', sm: 'none', md: 'none', lg: 'flex', xl: 'none' } }}>
                      <HSpecial variant="h3">Keeping Technology Simple <br /> Because it should be.</HSpecial>
                      </Box>
                      <Box sx={{ flexGrow: 1, display: { xs:'flex', sm: 'flex', md: 'flex', lg: 'none', xl: 'none' } }}>
                      <HSpecial variant="h4">Keeping Technology Simple <br /> Because it should be.</HSpecial>
                      </Box>
                      </Grid>
                
             
                </Grid>
            
            </MainTile>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} order={{xs: 2, sm: 2, md: 1, lg: 1 }}>
            <a target="_blank" href="https://www.collabtech.us" style={{ textDecoration: 'none', color: 'black' }}>
            <MainTile height='150px'>
            
            <Grid container alignContent="center" alignItems="center">
                  
                  <Grid item xs={6} sm={6} md={6} lg={6} sx={{ display: "flex", justifyContent: "center" }}><img src={CollabTech} width={200} /></Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}  sx={{ display: "flex", justifyContent: "center" }}><CursiveFont variant="h4" component="Block">Services<br />On Demand</CursiveFont></Grid>
              </Grid>
            </MainTile>
            </a>  
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} order={{xs: 3, sm: 3, md: 2, lg: 2 }}>
              <a target="_blank" href="https://www.truebluecrm.com" style={{ textDecoration: 'none', color: 'black' }}>
            <MainTile height='150px'>
            
            <Grid container alignContent="center" alignItems="center"  mt={2}>
                  
                  <Grid item xs={6} sm={6} md={6} lg={6} mt={2} sx={{ display: "flex", justifyContent: "center", marginTop: 20 }} alignContent="center" alignItems="center"><img src={TBC} width={125} /></Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} mt={3} sx={{ display: "flex", justifyContent: "center" }}><CursiveFont variant="h4" component="Block">Shared<br />Services</CursiveFont></Grid>
              </Grid>
            </MainTile>
            </a>
            
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} order={{xs: 1, sm: 1, md: 3, lg: 3 }} >
              <Link to="/careers" style={{ textDecoration: 'none', color: 'black' }}>
             
            <MainTile height='150px'>
            
            <Grid container alignContent="center" alignItems="center">
            <Grid item xs={6} sm={6} md={6} lg={6}   sx={{ 
    height: 100,
    display: "flex",
    justifyContent: "center" }}
    ><CursiveFont variant="h4" component="Block">Apply<br />Here</CursiveFont></Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} sx={{ display: "flex", justifyContent: "center" }} ><img src={DG} width={180} /></Grid>
                  
              </Grid>
            </MainTile>
            </Link>
            </Grid>
        </Grid>
        </motion.div>
    </React.Fragment>
  );
}