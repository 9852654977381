import * as React from 'react';
import { motion } from 'framer-motion';
import SinglePaper from '../templates/SinglePaper';
import CursiveFont from '../atoms/cursiveFont';



export default function Journeyperson() {
    return (
      <React.Fragment>
        <motion.div 
        className=''
        initial={{width: 0}}
        animate={{width: '100%'}}
        exit={{x: window.innerWidth, transition: {duration: 0.3}}}
        >

            <SinglePaper Header="Journeyperson">
                <CursiveFont>Hello</CursiveFont>

            </SinglePaper>
            </motion.div>
            </React.Fragment>
  );
}