import * as React from 'react';
import SinglePaper from '../templates/SinglePaper';
import CursiveFont from '../atoms/cursiveFont';
import { motion } from 'framer-motion';



export default function employeeDashboard() {
  return (
    <React.Fragment>
      <motion.div 
      className=''
      initial={{opacity:0}}
      animate={{opacity:1}}
      exit={{opacity:0}}
      >
    <SinglePaper Header="Action Board">
                <CursiveFont>Hello</CursiveFont>

            </SinglePaper>
      </motion.div>
    </React.Fragment>
  );
}