import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import styled, {keyframes} from "styled-components";
import { Typography } from '@mui/material';
import GoogleFontLoader from 'react-google-font-loader';
import '../Answers.css';





const Frosted = styled(Typography) `
font-family: 'Roboto', cursive;
textAlign: "center";
`




export default function cursiveFont(props) {
  return (
    <React.Fragment>
      <CssBaseline />

        <Frosted variant={props.variant} align={props.align} >
            {props.children}
        </Frosted>

    </React.Fragment>
  );
}