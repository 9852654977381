import React, { Component, useState } from 'react'
import NavButton from '../atoms/navButton'
import { Grid, Typography, Box, Divider,  } from '@mui/material'
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Fame from '../molecules/Fame';
import Fortune from '../molecules/Fortune';
import Power from '../molecules/Power';
import Reach from '../molecules/Reach';


export default function Recognition(){

  
  

    const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
    return (
        <>
       <Box sx={{ maxWidth: { xs: 320, sm: 480, md:600, lg:900, xl:900 }, typography: 'body1' }}>
      <TabContext value={value}
      
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example"
           variant="scrollable"
           scrollButtons
            allowScrollButtonsMobile
            textColor="secondary"
            sx={{
              "& .MuiTabs-indicator": { backgroundColor: "#2f2e41" },
              "& .MuiTab-root.Mui-selected": { color: "#2f2e41", fontWeight:"700", fontSize:"24px" },
            }}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#2f2e41"
               }
              }}
              centered
          >
            <Tab  label="Fame" value="1" />
            <Tab label="Fortune" value="2" />
            <Tab label="Power" value="3" />
            <Tab label="Reach" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1"><Fame /></TabPanel>
        <TabPanel value="2"><Fortune /></TabPanel>
        <TabPanel value="3"><Power /></TabPanel>
        <TabPanel value="4"><Reach /></TabPanel>
      </TabContext>
    </Box>




    </>
    );

}
