import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import styled from "styled-components";
import ReactPlayer from 'react-player';
import '../Answers.css';





const StyledPlayer = styled(ReactPlayer) `

  `


export default function VideoPlayer(props) {
  return (
    <React.Fragment>
      <CssBaseline />
      <div className='wrapper' >
        <StyledPlayer
          url={props.url}
          playing ={props.playing}
          muted={props.muted}
          loop={props.loop}
          controls={props.controls}
          width={props.width}	
        />
      </div>
    </React.Fragment>
  );
}