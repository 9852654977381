import React from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import Input from "@material-ui/core/Input";
import { Grid } from "@material-ui/core";
import { useState } from "react";
import Creatable from 'react-select/creatable';
import { TextField, Button } from "@mui/material"; 
import CursiveFont from "../atoms/cursiveFont";
import { db } from "../atoms/firebase";
import {collection, addDoc, Timestamp} from 'firebase/firestore';


export default function ContactForm(props) {
    const [token, setToken] = useState("");

    
  const { control, handleSubmit, formState: { errors }, getValue } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      description: '',

    }
  });
  const onSubmit = async(data, e) =>{
    props.setLoader(true);
    props.setSubmitted(true);
    try {
      const contactCollectionRef = collection(db, 'contactUs');
      const docRef = await addDoc(contactCollectionRef, {
        firstname: data.firstName,
        lastname: data.lastName,
        email: data.email,
        phone: data.phone,
        description: data.description,
        created: Timestamp.now(),
        site:"ANSWERSLLC",
      });
      props.setSuccessful(true);
      props.setLoader(false);
    } catch (err) {
      alert(err.message);
      props.setLoader(false);;
    }
  }

  return (
    <Grid container alignItems="center" justify="center" direction="column" paddingTop="10%">
    <form onSubmit={handleSubmit(onSubmit)}>
       
            <Grid item>
       
                    <Controller
                        name="firstName"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <TextField 
                        
                        label="First Name"
                        error={Boolean(errors.firstName)}
                        margin="dense"
                        helperText={errors.firstName && 'First Name is required'}
                        fullWidth 
                        {...field} />}
                    />
            </Grid>
            <Grid item>

                    <Controller
                        name="lastName"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <TextField {...field} 
                        label="Last Name"
                        error={Boolean(errors.lastName)}
                        margin="dense"
                        helperText={errors.lastName && 'Last Name is required'}
                        fullWidth 
                        />}
                    />

            </Grid>
            <Grid item>
               
                    <Controller
                        name="email"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <TextField {...field} 
                        label="Email"
                        error={Boolean(errors.email)}
                        margin="dense"
                        helperText={errors.email && 'Email is required'}
                        fullWidth 
                        />}
                    />
       
            </Grid>
            <Grid item>
       
                    <Controller
                        name="phone"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <TextField {...field} 
                        label="Phone"
                        error={Boolean(errors.phone)}
                        margin="dense"
                        helperText={errors.phone && 'Phone is required'}
                        fullWidth 
                        />}
                    />
           
            </Grid>
        
            <Grid item>
     
                    <Controller
                        name="description"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <TextField {...field}
                        multiline
                        rows={6}
                        label="Message"
                        error={Boolean(errors.description)}
                        margin="dense"
                        helperText={errors.description && 'Please tell us more'}
                        fullWidth 
                        />}
                    />
         
            </Grid>
            <Grid item>
                
                <Button id="contact-submit" sx={{color:"black"}} type="submit" >Send It</Button>
            </Grid>
     
        </form>
    </Grid>
  );
};
