import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import MainTile from '../atoms/Maintile';
import CursiveFont from '../atoms/cursiveFont';
import { Grid, Paper, Box } from '@mui/material';
import { motion } from 'framer-motion';






export default function Master() {
  return (
    <React.Fragment>
      <motion.div 
      className=''
      initial={{width: 0}}
      animate={{width: '100%'}}
      exit={{x: window.innerWidth, transition: {duration: 0.3}}}
      >

        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1, xl:1 }} alignContent="center" alignItems="center" mt={4}>
              <MainTile>
               
              <Grid container direction="row"
  justifyContent="center"
  alignItems="stretch">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign="center" p={2}>
              <CursiveFont variant="h4">Master</CursiveFont>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={12} xl={12} p={4}>
              <Paper>
                <Box p={8}>
                <CursiveFont >
hello
                </CursiveFont>
                </Box>

              </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} p={2} textAlign="center"  justifyContent="center"
  alignItems="stretch" >
              <CursiveFont variant="h6">Our company doesn't have the solution to the world's problems, but we can work with the organizations and individuals moving us in the right direction. Our goal is to accelerate them. To make them powerful. 
              </CursiveFont>
              </Grid>
            </Grid>
            
            </ MainTile>
        </Grid>

      </motion.div>
    </React.Fragment>
  );
}