import {createTheme, responsiveFontSizes } from "@mui/material";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";


const breakpoints = createBreakpoints({});

let theme = createTheme({
    breakpoints,
    palette: {
        primary: {
            main: '#A2A2A2'
        },
        secondary: {
            main: '#2f2e41'
        }
    },
    custom: {
        mainTile: {
          backgroundColor: '#A2A2A2',
          boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: '#000'
        }, 
        indicator: {
            backgroundColor: 'white',
          },
    }, 
    
      
});


function pxToRem(value) {
    return `${value / 16}rem`;
  }


export default theme;