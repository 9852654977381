
import './Answers.css';
import MainTile from './atoms/Maintile';
import Home from './pages/HomePageBlock';
import { useState, useEffect } from "react";
import {
  BrowserRouter,
  Link,
  useLocation,
  Route,
  Routes,
  Router
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import theme from './theme/theme';

import NoMatch from './pages/noMatch';


function App() {
  return (

   

    <div className="App">

<p>Hi</p>

    </div>
  );
}



export default App;
