import * as React from 'react';
import { Grid, Box, List, ListItem, ListItemText, Divider } from '@mui/material';
import Famepic from '../fame.svg';
import CursiveFont from '../atoms/cursiveFont';



export default function Fame(props) {

    return (
        <React.Fragment>
        <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign="center">
              <Box sx={{ flexGrow: 1, display: { xs:'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }, p:2 }} >
                  <CursiveFont variant="h5">You're Our Greatest Influencer</CursiveFont>
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs:'flex', sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }, p:2 }}>
                <CursiveFont variant="h5">Master Skills While Building Your Brand</CursiveFont>
                </Box>
             
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item>
                  <Box sx={{ flexGrow: 1, display: { xs:'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }, p:2 }} >
                  <img style={{ maxWidth: 280 }} src={Famepic} />
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs:'flex', sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }, p:2 }}>
                  <img style={{ maxWidth: 220 }} src={Famepic} />
                </Box>
                  </Grid>
                  <Grid item>
                  <Box sx={{ flexGrow: 1, display: { xs:'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }, p:2 }} >
                  <List>
                    <ListItem disablePadding>
                    <ListItemText>
                    <CursiveFont>Promotional Profile w/ Achieved Skills</CursiveFont>
                        </ListItemText> 

                    </ListItem>
                    <ListItem disablePadding>
                    <ListItemText>
                    <CursiveFont>Paid Influencer Marketing Activities</CursiveFont>
                        </ListItemText> 

                    </ListItem>
                  
                    <ListItem disablePadding>
                    <ListItemText>
                    <CursiveFont>Merit Based Access & Ranking</CursiveFont>
                        </ListItemText> 

                    </ListItem>
                </List>
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs:'flex', sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }, p:2 }}>
                <List>
                    <ListItem disablePadding>
                    <ListItemText>
                    <CursiveFont>Promotional Profile w/ Achieved Skills & Work History</CursiveFont>
                        </ListItemText> 

                    </ListItem>
                    <ListItem disablePadding>
                    <ListItemText>
                    <CursiveFont>Paid Influencer Marketing Activities</CursiveFont>
                        </ListItemText> 

                    </ListItem>
                  
                    <ListItem disablePadding>
                    <ListItemText>
                    <CursiveFont>Merit Based Access & Ranking</CursiveFont>
                        </ListItemText> 

                    </ListItem>
                </List>
                </Box>
                    
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            </React.Fragment>
    );


}