import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import styled from "styled-components";
import { Button } from '@mui/material';
import { Link } from '@mui/material';
import CursiveFont from './cursiveFont';
import { NavLink } from "react-router-dom";  
import { animate, motion } from 'framer-motion';  


const StyledButton = styled(NavLink) `
font-family: 'Roboto', cursive;
font-weight:light-bold;


text-decoration:none;

display: flex;

  a {
    text-decoration: none;
  }

  li {
    color:black;
    margin:0 .8rem;
    font-size:1.4rem;
    position: relative;
    list-style: none;
  }

  .current {
    li {
      border-bottom: 2px solid black;
      text-decoration: none;
      margin:.2rem;
    }
  }
  :hover {
    text-decoration:underline black;
  }
`

export default function navButton(props) {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container whileHover={{ scale: 1.1 }} maxWidth={false} component={motion.div}>
      <StyledButton  activeClassName="current" to={props.address}><li>{props.children}</li></StyledButton>
      </Container>
    </React.Fragment>
  );
}