import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import MainTile from '../atoms/Maintile';
import CursiveFont from '../atoms/cursiveFont';
import { Grid, Paper, Box } from '@mui/material';
import { motion } from 'framer-motion';






export default function SinglePaper(props) {
  return (
    <React.Fragment>

        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1, xl:1 }} alignContent="center" alignItems="center" mt={4}>
              <MainTile>
               
              <Grid container direction="row"
  justifyContent="center"
  alignItems="stretch">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign="center" p={2} >
              <CursiveFont variant="h4">{props.Header}</CursiveFont>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={12} xl={12} p={4}>
              <Paper>
                <Box p={8}>
               {props.children}

              
                </Box>

              </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} p={2} textAlign="center" justifyContent="center"
  alignItems="stretch" >
           
              </Grid>
            </Grid>
            
            </ MainTile>
        </Grid>

  
    </React.Fragment>
  );
}