import {useState, useEffect} from 'react';
import throttle from 'lodash.throttle';

const getDeviceConfig = (width) => {
  if(width < 480) {
    return 'xs';
  } else if(width >= 480 && width < 1024 ) {
    return 'sm';
  } else if(width >= 1024 && width < 1280) {
    return 'md';
  } else if(width >= 1280 && width < 1600) {
    return 'lg';
  } else if(width >= 1600) {
    return 'xl';
  }
};

const useBreakpoint = () => {
  const [brkPnt, setBrkPnt] = useState(() => getDeviceConfig(window.innerWidth));
  
  useEffect(() => {
    const calcInnerWidth = throttle(function() {
      setBrkPnt(getDeviceConfig(window.innerWidth))
    }, 200); 
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return brkPnt;
}
export default useBreakpoint;