import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import styled, {keyframes} from "styled-components";
import { Typography } from '@mui/material';


const Frosted = styled(Typography) `
url("https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap");
font-family: 'Roboto', cursive;
text-decoration: underline;
`




export default function hSpecial(props) {
  return (
    <React.Fragment>
      <CssBaseline />

        <Frosted variant={props.variant} >
            {props.children}
        </Frosted>

    </React.Fragment>
  );
}